import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PostHogProvider } from 'crypsis/PostHog/PostHogProvider/PostHogProvider';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

// App error boundary
import ErrorBoundaryContainer from 'features/app/containers/ErrorBoundaryContainer';
import ContentBoundary from 'features/app/components/ContentBoundary/ContentBoundary.tsx';

// Maintenance screen
import MaintenanceScreen from 'features/app/components/MaintenanceScreen/MaintenanceScreen.tsx';

import LangHelmet from 'features/app/components/Helmets/LangHelmet.tsx';
import RootHelmet from 'features/app/components/Helmets/RootHelmet.tsx';

// App loading placeholder
import AppLoading from 'features/app/components/AppLoading/AppLoading.tsx';

import { AnalyticsProvider } from './features/analytics/AnalyticsProvider';
import { CompanySelectorContextProvider } from './features/ambasidebar/context/useCompanySelectorContext/useCompanySelectorContext';
import { lazyLoadWithRetry } from './core/lazyloadWithRetry/lazyloadWithRetry';
import useDatadog from './features/app/hooks/useDatadog/useDatadog';
import { ProductRequestContextProvider } from './features/skumarketplace/components/ProductRequestContextProvider/ProductRequestContextProvider';

const App = lazyLoadWithRetry(
  () => import('features/app/containers/AppContainer' /* webpackChunkName: "js/app" */),
  'app'
);
const AuthRoutes = lazyLoadWithRetry(
  () => import('features/auth/components/AuthRoutes/AuthRoutes' /* webpackChunkName: "js/auth" */),
  'auth'
);
const LandingPage = lazyLoadWithRetry(
  () => import('features/landing/components/LandingPage.tsx' /* webpackChunkName: "js/landing" */),
  'landing'
);

const RedirectToFullUrl = lazyLoadWithRetry(
  () =>
    import(
      'features/shortenedurls/components/RedirectToFullUrl/RedirectToFullUrl' /* webpackChunkName: "js/shortened-urls" */
    ),
  'redirect'
);
const SaleAccepted = lazyLoadWithRetry(
  () =>
    import(
      'features/dispatch/components/SaleAccepted/SaleAccepted' /* webpackChunkName: "js/sale-accepted" */
    ),
  'sale-accepted'
);

const AppRoot = ({ history }) => {
  useDatadog();

  return (
    <ErrorBoundaryContainer component={ContentBoundary}>
      <LangHelmet />
      <RootHelmet />
      <React.Suspense fallback={<AppLoading />}>
        <Router history={history}>
          <AnalyticsProvider>
            <CompanySelectorContextProvider>
              <PostHogProvider
                apiHost={process.env.POSTHOG_PUBLIC_HOST}
                publicKey={process.env.POSTHOG_PUBLIC_KEY}
              >
                <Switch>
                  <Route exact path="/">
                    <LandingPage />
                  </Route>
                  <Route exact path="/login" component={AuthRoutes} />
                  <Route exact path="/s/:token" component={RedirectToFullUrl} />
                  <Route exact path="/maintenance" component={MaintenanceScreen} />
                  <Route exact path="/sale/a/:token" component={SaleAccepted} />
                  <Route>
                    <ProductRequestContextProvider>
                      <App />
                    </ProductRequestContextProvider>
                  </Route>
                </Switch>
              </PostHogProvider>
            </CompanySelectorContextProvider>
          </AnalyticsProvider>
        </Router>
      </React.Suspense>
    </ErrorBoundaryContainer>
  );
};

AppRoot.propTypes = {
  history: PropTypes.shape().isRequired
};

export default Sentry.withProfiler(AppRoot);
