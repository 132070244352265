import humps from 'humps';
import Analytics from 'core/analytics';
import { logout, storeToken, storeRoles } from 'core/auth';
import http from 'core/http';
import i18n from 'core/i18n';
import * as CompanyActionTypes from 'features/company/actionTypes';
import * as ActionTypes from './actionTypes';

// @TODO better error handling
/* eslint-disable */

/**
 * Endpoints
 */
const auth = {
  login: '/api/v1/auth/login',
  loginToken: '/api/v1/auth/login-token',
  register: '/api/v1/auth/register',
  logout: '/api/v1/auth/logout',
  password: '/api/v1/auth/set-password',
  me: '/api/v1/me',
  emailLogin: (token) => `/api/v1/auth/get-started/${token}`
};

/**
 * --- AUTH UTILITIES ---
 */
function trackSuccessfulLogin(user, company) {
  Analytics.track('login_success_app', {
    $name: `${user.firstname} ${user.lastname}`,
    $email: user.email,
    Company: company.name,
    Type: company.type === 0 ? 'Supplier' : 'Buyer',
    Joined: user.joined
  });
}

function trackUnsuccessfulLogin(err) {
  const error = err.response && err.response.data ? err.response.data : err.message;

  Analytics.track('login_error_app', { error });
}

const handleJwtResponse = (res, dispatch) => {
  const response = humps.camelizeKeys(res.data);
  const { user, accessToken } = response;
  const { company } = user;

  storeToken(accessToken);
  storeRoles(user.roles);

  dispatch({
    type: ActionTypes.AUTH_LOGIN,
    payload: user
  });

  dispatch({
    type: CompanyActionTypes.COMPANY_UPDATE,
    payload: company
  });

  trackSuccessfulLogin(user, company);

  return { user, company };
};

/**
 * Check if a user is authenticated
 *
 * We check if the localStorage has a token if it does we consider user authenticated
 * If token is expired he will get redirected to /login
 *
 */
export const authCheck = () => {
  const isAuthenticated = !!localStorage.getItem('token');
  return {
    type: ActionTypes.AUTH_CHECK,
    payload: isAuthenticated
  };
};

/**
 * Login the user
 *
 * @param {*} credentials Email and password
 *
 */
export const authLogin = (credentials) => (dispatch) =>
  http
    .post(auth.login, credentials)
    .then((res) => handleJwtResponse(res, dispatch))
    .catch((err) => {
      trackUnsuccessfulLogin(err);
      return Promise.reject(err);
    });

/**
 * Login the user with the provided token.
 */
export const authLoginWithToken = (token) => (dispatch) => {
  localStorage.setItem('token', token);
  return http
    .get(auth.loginToken)
    .then((res) => {
      handleJwtResponse(res, dispatch);
    })
    .catch((err) => {
      localStorage.removeItem('token');
      trackUnsuccessfulLogin(err);
      throw err;
    });
};

/**
 * Logout a user
 *
 * Will delete the token in the local storage and empty the store.
 * JWT uses middleware that auto-refresh token on backend side
 *
 * @param isUserAction Is the logout action initated by the user itself?
 */
export const authLogout =
  ({ isUserAction = false, redirectToLogin = true } = {}) =>
  (dispatch) => {
    return http.post(auth.logout).then(() => {
      logout({ isUserAction, redirectToLogin });
      dispatch({ type: 'RESET' });
    });
  };

/**
 * Sets the language to the user's language and registers the provided user and company with Mixpanel, GA
 *
 * @param {object} user
 * @param {object} company
 */
export const registerUser = (user, company) => () => {
  // Sync language locale
  i18n.changeLanguage(user.language);
};
