import { classnames } from '@rooser/reef';

export const containerClasses = classnames(
  'col-start-4',
  'col-end-10',
  'py-32',
  'text-center',
  'medium:col-start-2',
  'medium:col-end-8',
  'small:col-start-1',
  'small:col-end-5'
);

export const illustrationContainerClasses = classnames('h-32', 'mb-8');

export const buttonContainerClasses = classnames('flex', 'flex-row', 'justify-center', 'mt-6');

export const goBackButtonClasses = classnames('mr-2');
