import React from 'react';
import { QueryClientProvider as ReactQueryClientProvider } from 'react-query';
import useQueryClient from '../../hooks/useQueryClient/useQueryClient';

export interface QueryClientProviderProps {
  children: React.ReactNode;
}

const QueryClientProvider = ({ children }: QueryClientProviderProps): ReactElement => {
  const { queryClient } = useQueryClient();
  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
};

export default QueryClientProvider;
