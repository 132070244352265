import React, { ReactElement } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';
import useAuth from 'features/auth/hooks/useAuth';
import { useCurrentCompany } from 'features/app/hooks/useCurrentCompany/useCurrentCompany';
import { LocalStorageFlagProvider } from 'crypsis/LocalStorageFlagProvider/LocalStorageFlagProvider';

export type PostHogProviderProps = React.PropsWithChildren<{
  apiHost?: string;
  publicKey?: string;
}>;

export const PostHogProvider = ({
  apiHost = null,
  children,
  publicKey = null
}: PostHogProviderProps): ReactElement => {
  // For testing purposes we will not enable PostHog in environments where the key is not specified.
  const enabled = typeof publicKey === 'string' && publicKey !== '';

  const { user } = useAuth();

  const currentCompany = useCurrentCompany();
  const currentCompanyId = currentCompany?.id;
  const currentCompanyName = currentCompany?.name;

  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    posthog.init(publicKey, {
      autocapture: true,
      api_host: apiHost,
      disable_session_recording: true,
      person_profiles: 'identified_only',
      loaded: () => setIsReady(true)
    });
  }, [apiHost, enabled, publicKey, setIsReady]);

  const userExists = !!user;

  const {
    company: { name },
    id,
    companyId,
    email
  } = user ?? { company: {} };

  React.useEffect(() => {
    if (!isReady) {
      return;
    }

    if (!userExists || !id) {
      posthog.stopSessionRecording();
      posthog.reset();

      return;
    }

    posthog.startSessionRecording();

    posthog.identify(id.toString(), {
      companyName: name,
      email
    });
    posthog.group('company', companyId?.toString(), {
      name
    });
    if (currentCompanyId) {
      posthog.group('controlledCompany', `${currentCompanyId}`, {
        name: currentCompanyName
      });
    }
  }, [isReady, userExists, name, id, companyId, email, currentCompanyId, currentCompanyName]);

  return (
    <LocalStorageFlagProvider enabled={!enabled}>
      <Provider client={enabled ? posthog : null}>{children}</Provider>
    </LocalStorageFlagProvider>
  );
};
