/**
 * Sort an array by a property
 * Usage: myArray.sortBy('age')
 *  => [...]
 */
/* eslint-disable */
Array.prototype.sortBy = (function () {
  const sorters = {
    string(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },

    number(a, b) {
      return a - b;
    }
  };

  return function (prop) {
    const type = typeof this[0][prop] || 'string';
    return this.sort((a, b) => sorters[type](a[prop], b[prop]));
  };
})();

/**
 * Group array by key
 *
 *  Usage: myArray.groupBy('species.name');
 *   => [Cod: [...], Ling: [...]]
 *
 * @param {*} data The array of data
 * @param {*} accessor The property accessor to group by
 *
 * @return array Array of array grouped by keys
 */

Array.prototype.groupBy = (function () {
  return function (accessor) {
    if (this.length === 0 || !accessor) {
      return [];
    }

    const grouped = this.reduce((groups, item) => {
      if (!item) {
        return groups;
      }

      let groupBy = accessor;
      const keys = accessor.split('.');
      if (keys.length > 0) {
        groupBy = keys.reduce((acc, key) => acc[key], item);
      }

      groups[groupBy] = groups[groupBy] || [];
      groups[groupBy].push(item);
      return groups;
    }, []);

    return {
      grouped,
      list: Object.keys(grouped)
    };
  };
})();

/**
 * Truncate a string
 *
 * @param int n The length to truncate
 * @param bool useWordBoundary truncate at the next word's end
 *
 * @return string
 */
String.prototype.trunc = function (n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  const subString = this.substr(0, n - 1);
  return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}…`;
};
