import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { selectIsAuthenticated, selectCurrentUser } from '../../auth/selectors';
import { selectCompany } from '../../company/selectors';

export interface ErrorBoundaryProps {
  component: (props: any) => ReactElement;
  children: React.ReactNode;
}

export interface ErrorBoundaryState {
  error: any;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: null };

  errorCode = `ERR-${Date.now()}`;

  static getDerivedStateFromError(error: any): ErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: any, info: any): void {
    const metadata = {
      errorCode: this.errorCode,
      error,
      info
    };
    // eslint-disable-next-line no-console
    console.log('error', metadata);
    datadogRum.addError(metadata);
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { component: Component, children } = this.props;

    if (error && Component) {
      return <Component error={error} errorCode={this.errorCode} />;
    }

    return children;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  user: selectCurrentUser(state),
  company: selectCompany(state)
});

export default connect(mapStateToProps, null)(ErrorBoundary);
